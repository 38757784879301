





import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import { Button, Dropdown, Space } from 'antd';
import { IconContext } from "react-icons";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import parse from 'html-react-parser'



const BookDetails = () => {


    const [bookObj, setBookObj] = useState({})
    const [loading, setLoading] = useState(true)
    const [loginModel, setloginModel] = useState(false)
    const [loggedIn, setloggedIn] = useState(false)
    const [username, setusername] = useState("")
   const [flyingLinkLoading, setflyingLinkLoading] = useState()
   const [ moreAboutAuthor, setmoreAboutAuthor] = useState() 
   const [ AboutThisEdition, setAboutThisEdition] = useState()
   const [buyBook, setbuyBook] = useState("")
   const [gbname,setgbname] = useState("")
   const [authorPic, setauthorPic] = useState("")
   const [AboutThisAuthor, setAboutThisAuthor] =  useState("")
   const [bookseenthismonth, setbookseenthismonth] = useState(0)
   const [bookseentoday, setbookseentoday] = useState(0)
   
    

    const [inputs, setInputs] = useState({});
 
  const pwRef = useRef(null)
  const checkboxRef = useRef(null)
    
    let { state } = useLocation();


    useEffect(()=>{

        console.log(state.bnumber)
        fetchBookDetails(state.bnumber)
    
        console.log("useEffect",state.bnumber)
        
    },[])

    //var yHost = "http://localhost:8087"
    var yHost = "" 

    const fetchBookDetails = (bnumber) => {
        //setLoading(true)
         axios({
           method: 'post',
           url: `${yHost}/booksG/fetchBookDetails`,
           withCredentials: true, //ye cookies carry karega

           data:{ bnumber: bnumber }

         })
       .then((response) => {
         const data = response.data
         console.log(data)
         setBookObj(data.bookObj)
         
 
         if(data.username){
           setusername(data.username); setloggedIn(true)
           setbookseentoday(data.bookseentoday)
          setbookseenthismonth(data.bookseenthismonth)
       
         }else{setloggedIn(false)}
         
         setLoading(false)
         console.log("username", data.username)
       })
       .catch((error) => console.log(error));
         
     }


    const showPW = ()=>{
        const checkbox = checkboxRef.current
        const x = pwRef.current
        if(checkbox.checked){
          x.type = "text"
        }
       else{
         
           x.type = "password"
       } 
    }


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
      }


    const login = async (e) => {
        e.preventDefault();
       

        axios({
          method: 'post',
          url: `${yHost}/usersG/login`,
          data: inputs
          
        })
      .then((response) => {
        const data = response.data;
        console.log(data)
        console.log(data.message)
        if(data.message=="you are logged in"){ setloggedIn(true) ; setloginModel(false) }
        else{}
      })
      .catch((error) => console.log(error));

      //setloggedIn(true) ; setloginModel(false) 

      }


      const logOut=async()=>{
        const result = await fetch('/booksG/logout', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({}),
            
        })

        .then((res) => res.json())
        console.log(result.message)
        alert(result.message)
        window.location.replace("/")
      }

      
     const createFlyingLink = () => {
        console.log('createFlyingLink', bookObj.bnumber)
      var bnumber = bookObj.bnumber
        setflyingLinkLoading(true)
        axios
          .get(`${yHost}/createFlyingLink/${bnumber}`)
    
          .then((response) => {
            const data = response.data;
            console.log(data.msg)
            if (data.msg === "created") {
              setflyingLinkLoading(false)
              var bookObjLocal = bookObj
              bookObjLocal.isflyingLinkCreated = true
              console.log("bookObjLocal",bookObjLocal)
              setBookObj(bookObjLocal)


            }
            if (data.msg === "error") {
              setflyingLinkLoading(false)
              alert("Some error in Making fast link, probably due to book is very lengthy, please request admin to make this link for you")
            }
            if (data.msg === "wait") {
              setflyingLinkLoading(false)
              alert("Process of creating this Book's Fast link is running, please wait, and refresh page after 10 minutes")
            }
    
    
    
          })
          .catch((error) =>{ console.log(error);  setflyingLinkLoading(false)});
    
      }

     const moreBooksByAuthor=()=>{

      if(!bookObj.googleID){  alert("google ID not available") ; return }
    
        setLoading(true)
        axios({
          method: 'post',
          url: `${yHost}/fetchMoreAboutAuthor/${bookObj.googleID}`,
          withCredentials: true,
        })  .then((response) => {
          const data = response.data;
          console.log("moreBooksByAuthor",data.moreAboutAuthor)
         
         
          setLoading(false)
          setmoreAboutAuthor(data.moreAboutAuthor)

        })   .catch((error) => {console.log(error)});
    
      }


    const fetchDetail=(googleID, bnumber)=>{
       
      setLoading(true)
       
        axios({
          method: 'post',
          url: `${yHost}/fetchBookDetails/${bookObj.googleID}/${bookObj.bnumber}`,
          withCredentials: true,
        })  .then((response) => {
         // const data = response.data;
         console.log(response.data)
         setLoading(false)
        setAboutThisEdition(response.data.AboutThisEdition)
        setbuyBook(response.data.buyBook)
        setgbname(response.data.gbname)
        setauthorPic(response.data.authorPic)
        setAboutThisAuthor(response.data.AboutThisAuthor)
          
       

        }).catch((error) => {console.log(error);    } );
    
      }


      const items = [
        {
          key: '1',
          style: {backgroundColor:"green"},
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              1st menu item
            </a>
          ),
        },
       
        {
          key: '2',
          label: (
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
              3rd menu item (disabled)
            </a>
          ),
          disabled: true,
        },
        {
          key: '3',
          onClick:(e)=>logOut(),
          label: 'log out',
        },
      ];  




    return (
        <div >
            {loggedIn? 
     
     <div  
     style={{
       zIndex:"1000",
       position: "absolute",
       top: "20px",
       right: "20px",
       padding:"10px"
     }}
     
   >
<Dropdown
    
    menu={{
      items,
    }}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Space>
     
       <IconContext.Provider
         value={{
           size: "30px",
           color: "orange",
           className: "global-class-name",
         }}
       >
         <div>
           <FaUserCircle className='hoverSearch arrow' />
         </div>
       </IconContext.Provider>
      

        
      </Space>
    </a>
  </Dropdown>
  </div>

      :
      <Button
        type='primary'
          style={{
           
            position:"absolute",
            top: "20px",
            right: "20px",
          
            zIndex:"1000",
          }}
          onClick={() => setloginModel(!loginModel)  }   
        >
          Login
        </Button>
      }

{ loginModel?
        <div style={{ textAlign:"center", backgroundColor:"pink", width:"80%", height:"80%", zIndex:"100000", right:"0", left:"0", top:"0", bottom:"0" ,margin:"auto" , position:"absolute"}}>

           <Button style={{float:"right" , marginTop:"10px", marginRight:"10px"}} type='primary' onClick={()=> setloginModel(false)}>X</Button>


           <h1 style={{color:"#3498db", textAlign:"center", marginTop:"30px"}}>Log In</h1>

                     <form onSubmit={login}  >
                      <input  type='hidden' name='city' id="city"></input><br/><br/>

                      <input  autoFocus style={{width: "300",padding: "10px"}} type='text' 
                     name="username" 
                     value={inputs.username || ""} 
                     onChange={handleChange} 
                      minLength="1"
                      placeholder="Username or email or Mobile Number"></input><br/><br/>

                      <input style={{width: "300",padding: "10px"}} type='password' id='password' autoComplete="new-password"   name="password" 
                     value={inputs.password || ""} 
                     onChange={handleChange}
                      minLength="3" 
                      placeholder="password" 
                      ref={pwRef}></input><br/><br/>
                     <div > <input ref={checkboxRef} style={{width:"20px", height:"20px"}} type="checkbox" id="checkbox" onChange={()=>showPW()}></input> 
                     <label style={{verticalAlign:"top"}} htmlFor="vehicle1">Show Password</label> </div>

                  
                      <button style={{marginTop:"10px", marginBottom:"30px", backgroundColor:"orange", padding:"10px" }} type="submit">Submit</button>
                      
                      </form>
        
                  <p>You have five login attempts only, if you try with wrong user ID or
                      password, your account will be blocked for few hours</p>
                  <p >If by mistake, you blocked your account, wait for few hours, it will
                      be opened automatically</p>
                  <p >If forgot you password, or other login problems contact <span  style={{color:"#3498db", fontWeight: "600"}}>Ms Pooja 7875450033</span>.</p>    
                  <p >For suggestions of improvement, new book request, please whatsapp to
                      admin at +91 8591268994</p>
             
            
        </div> 
      : null}
            {loading?
            <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
            <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
            </div>
                 :
            <div style={{width:"100%",maxWidth:"600px", borderWidth:"1px", borderStyle:"solid", borderRadius:"5px", borderColor:"aqua" ,marginRight:"auto", marginLeft:"auto", display:"block",paddingLeft:"20px",marginTop:"60px"}}>
 
            <h1 style={{color:"white", textAlign:"center",marginTop:"20px"}}>{bookObj.bname}</h1>
            <img
                 
                  src={
                    `${yHost}/public/image/` +
                    bookObj.bnumber +
                    ".jpg"
                  }
                  style={
                      {
                        marginRight:"auto", marginLeft:"auto", display:"block", width: "90%", maxWidth:"300px", height:"auto", marginTop:"20px", marginBottom:"20px"
                      }
                  }
                
                  alt="Picture"
                />
              <p style={{color:"orange", textAlign:"center"}}>{bookObj.subject1}</p>
              <p style={{color:"orange",textAlign:"center"}}>{bookObj.subject2}</p>
              <p style={{color:"orange",textAlign:"center", marginBottom:"20px"}}>{bookObj.subject3}</p>


              <p><span style={{color:"orange"}}>Author: </span><span style={{color:"white"}}>{bookObj.author}</span></p>
              <p><span style={{color:"orange"}}>Edition: </span><span style={{color:"white"}}>{bookObj.edition}</span></p>
              <p><span style={{color:"orange"}}>Year of Publication: </span><span style={{color:"white"}}>{bookObj.year}</span></p>
              <p><span style={{color:"orange"}}>Pages: </span><span style={{color:"white"}}>{bookObj.year}</span></p>
              { bookObj.ISBN != "Searching" ?
               
                <p><span style={{color:"orange"}}>ISBN-13: </span><span style={{color:"white"}}>{bookObj.ISBN}</span></p>
                
               : null }
              <p><span style={{color:"orange"}}>google Books Price: </span><span style={{color:"white"}}>{bookObj.gPrice}</span></p>
              <p><span style={{color:"orange"}}>Amazon Kindle eBook Price: </span><span style={{color:"white"}}>{bookObj.kindlePrice}</span></p>
              <p><span style={{color:"orange"}}>Kobo store Price: </span><span style={{color:"white"}}>{bookObj.koboPrice}</span></p>
              <p style={{ color: "white" }}>Now we have started bookshop to diliver all medical books in best price. call or WhatsApp to know our price.</p>
            
             
               <div style={{display:"flex", flexDirection:"row", flexWrap: "wrap", justifyContent: "center", marginTop:"20px"}}>
              <Button type="primary" style={{marginLeft:"20px", marginRight:"20px", marginBottom:"20px"}}>Present Price</Button>

              <Button type="primary" style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px"}} onClick={()=>fetchDetail()}>More about Book</Button>

              <Button type="primary" style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px"}} onClick={()=>moreBooksByAuthor()}>More about Author</Button>

              {username? 
              <div style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px", width:"130px"}}>
              <Button style={{width:"110px"}} type="primary"  onClick={()=>{  window.open("/showbook/"+bookObj.bnumber, '_blank').focus()  }} >Read Book</Button>
             <p style={{color:"white", fontSize:"9px"}}>Books Read today: {bookseentoday}</p>
             <p style={{color:"white", fontSize:"9px"}}>Books Read this month: {bookseenthismonth}</p>
              </div>:null}
              
              {username? 
              <div>
              { !bookObj.isflyingLinkCreated && !flyingLinkLoading?  <Button type="primary" style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px"}} onClick={()=>createFlyingLink()}>Create Fast Link</Button>
              :
              !bookObj.isflyingLinkCreated && flyingLinkLoading? <SpinningCircles style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px", display:"block"}} />:
               <Button type="primary" style={{marginLeft:"20px", marginRight:"20px",marginBottom:"20px"}} >Fast Link</Button>
               }
               </div>:null}
              
              
               </div>

               {AboutThisEdition?
           <div style={{maxWidth:"600px", marginRight:"auto", marginLeft:"auto", display:"block"}}>
            <h5 style={{color:"#fff", textAlign:"center"}}>{gbname}</h5>
            <h6 style={{color:"#fff", textAlign:"center"}}><u>Details</u></h6>
           
              { AboutThisEdition.split("###").map((i,index)=>{  return  <p key={index} style={{color:"#fff", marginLeft:"20px"}}>{i}</p>  }) }

              <h6 style={{color:"#fff", textAlign:"center"}}>Author</h6>
           
           {AboutThisAuthor.split("###").map((i,index)=>{   if(i.match(/^Search/i)){} else{ return  <p key={index} style={{color:"#fff", marginLeft:"20px"}}>{i}</p> }          }) }

         
           <br/>
          {authorPic ?
          <img style={{marginLeft:"20px"}} alt="" src={authorPic}></img>
          :null}
           <br/>

       
          <br/>
      
          {buyBook?
          <div>
          <h6 style={{color:"#fff", textAlign:"center"}}>buy Book from stores other then us</h6>
          { buyBook.split("###").map((i,index)=>{   if(i.match(/^Search/i)){} else{ return  <p key={index} style={{color:"#fff", marginLeft:"20px"}}>{i}</p> }          }) }
          </div>
          :null
          } 
          <br/> 
        
          
          
           
        

           

           </div>:null}

               {moreAboutAuthor?
                <div style={{color:"#fff"}}> {parse(moreAboutAuthor)}</div>
                : null
                }

       



       
           

            
       
              


            </div>
             }
           
        </div>
    )
    
}

export default BookDetails;
  