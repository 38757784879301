
import React, { useEffect, useState, useRef } from "react";





import { Outlet, Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";


import { Button, Dropdown, Space } from 'antd';
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import { GoChevronLeft ,GoChevronRight} from "react-icons/go";
import { IconContext } from "react-icons";
import { FaSearch, FaUserCircle } from "react-icons/fa";






const contStyle = {
  display: "flex",
  flexDirection:"row",
  flexWrap: "nowrap",
  paddingTop:"50px",
  height:"700px"
  
}

const containerStyle = {
  maxWidth: '1280px',
  margin: '0 auto',
 
}





function App() {

  const [file, setFile] = useState();
   const[signUpModel,setsignUpModel] = useState(false)
    const [username, setusername] = useState("")
    const [hover, setHover] = useState("")
    const [loginModel, setloginModel] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loggedIn, setloggedIn] = useState(false)



    
    const formElemRef = useRef()
    const navigate = useNavigate();

    const [bookArrSH, setbookArrSH] = useState([  ]); 
    const [bookArrMoti, setbookArrMoti] = useState([  ]); 
    const [bookArrSports, setbookArrSports] = useState([  ]); 
    const [bookArrBio, setbookArrBio] = useState([  ]); 
    const [bookArrYoga, setbookArrYoga] = useState([  ]); 
    const [bookArrHealth, setbookArrHealth] = useState([  ]); 
    const [bookArrCooking, setbookArrCooking] = useState([  ]); 
    const [bookArrBS, setbookArrBS] = useState([  ]); 
    const [bookArrFC, setbookArrFC] = useState([  ]); 



    const [inputs, setInputs] = useState({});
    const [inputs1, setInputs1] = useState({});
 
  const pwRef = useRef(null)
  const pw1Ref = useRef(null)
  const pw2Ref = useRef(null)
  const checkboxRef = useRef(null)
  const checkbox1Ref = useRef(null)
  

  const divRefSH = useRef(null)
  const divRefMoti = useRef(null)
  const divRefSports = useRef(null)
  const divRefBio = useRef(null)
  const divRefYoga = useRef(null)
  const divRefHealth = useRef(null)
  const divRefCooking = useRef(null)
  const divRefBS = useRef(null)
  const divRefFC = useRef(null)


  const bottomSH = useRef(null);
  const bottomMoti = useRef(null);
  const bottomSports = useRef(null);
  const bottomBio = useRef(null);
  const bottomYoga = useRef(null);
  const bottomHealth = useRef(null);
  const bottomCooking = useRef(null);
  const bottomBS = useRef(null);
  const bottomFC = useRef(null);



    function handleFileChange(e) {
    // console.log(e.target.files[0])
    // console.log(e.target.files[0].uri)
    // console.log(e.target.files[0].url)

     if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
      setFile(URL.createObjectURL(e.target.files[0]));
  }


//var yHost = "http://localhost:8087"
var yHost = ""



  const handleSubmit=(e)=>{
    e.preventDefault()
    if(!inputs1.username){alert("please choose a unique username"); return}

    if(!inputs1.email){alert("please enter correct email id"); return}
    if(inputs1.email !="" && (!inputs1.email.includes("@") || !inputs1.email.includes(".")) ) {alert("please enter correct email id"); return}
   
    if(!inputs1.newPassword){alert("please choose a password"); return}
    if(inputs1.newPassword.length < 6){alert("password should be min 6 character long"); return}
    if(!inputs1.cpassword){alert("please type again your password in confirm password field"); return}

    if(inputs1.newPassword && !inputs1.cpassword){alert("enter password once again in confirm pasdword field"); return}
    if(inputs1.newPassword &&   inputs1.newPassword != inputs1.cpassword){alert("you entered different password in confirm password field"); return}

    if(!inputs1.phone){alert("please provide your phone number in international format without leading +"); return}
    if(!file){alert("please upload a profile picture, max 2mb, jpg or png"); return}

      const formData = new FormData(formElemRef.current);

     // formData.append('pic', file);
    
    // if(email){data.email = email}
    // if(password){data.password = password }
    // if(phone){data.phone = phone }

     console.log("formData",formData)
    axios.post(`${yHost}/usersG/makeMyAccount`, formData, 

        
        //    { headers: {
        //       'accept': 'application/json',
        //       'Accept-Language': 'en-US,en;q=0.8',
        //       'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        //     }}

    )
    .then((response) => {
        const data = response.data;
       if(data.msg == "success"){alert("updated");
        //location.reload();
        navigate("/")
      }
      })
      .catch((error) => console.log(error));

 }

  useEffect(() => {
    
    const fetchBooks=()=>{ 
         setLoading(true)

  //     axios.post(
  
  //        '/booksG/fetchAttractiveBooks',
          
  //      {
        
 
  // 'origin': "",
  // 'referer': "/"

  //      } )



       axios({
        method: 'post',
        url: `${yHost}/booksG/fetchAttractiveBooks`,
        withCredentials: true //ye cookies carry karega
      })

      .then((response) => {
        const data = response.data;
        if(data.username){
          setusername(data.username)
          setloggedIn(true)
        }else{
          setloggedIn(false)
        }
        setbookArrSH( data.SHBooksArr  )
        setbookArrMoti( data.MotiBooksArr )

        setbookArrSports( data.SportsBooksArr  )
        setbookArrBio( data.BioBooksArr  )
        setbookArrYoga( data.YogaBooksArr  )
        setbookArrHealth( data.HealthBooksArr  )
        setbookArrCooking( data.CookingBooksArr  )

        setbookArrBS( data.BSBooksArr  )
        setbookArrFC( data.FCBooksArr  )
       
    
    
       //setTimeout(setLoading(false),1000) 

   

        setLoading(false)
        
      })
      .catch((error) => console.log(error));
      }
      fetchBooks();
  }, []);




  useEffect(() => {


    const observerSH = new IntersectionObserver((entries) => {

      if (entries[0].isIntersecting) {
        fetchBooksMore ("SH");
      }
    });
    observerSH.observe(bottomSH.current);




    const observerMoti = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Moti");
      }
    });
    observerMoti.observe(bottomMoti.current);


    const observerSports = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Sports");
      }
    });
    observerSports.observe(bottomSports.current);


    const observerBio = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Bio");
      }
    });
    observerBio.observe(bottomBio.current);


    const observerYoga = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Yoga");
      }
    });
    observerYoga.observe(bottomYoga.current);

    const observerHealth = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Health");
      }
    });
    observerHealth.observe(bottomHealth.current);

    const observerCooking = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("Cooking");
      }
    });
    observerCooking.observe(bottomCooking.current);


    const observerBS = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("BS");
      }
    });
    observerBS.observe(bottomBS.current);


    const observerFC = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        fetchBooksMore ("FC");
      }
    });
    observerFC.observe(bottomFC.current);




  }, []);

 


  async function fetchBooksMore(bookSubj) {
    axios({
      method: "post",
      url: `${yHost}/booksG/fetchAllBooks`,
      data: { bookSubj: bookSubj },
    })
      .then((response) => {
        const data = response.data;

        if (bookSubj == "SH") {
          console.log("fetchmore reply aaya SH", response.data.Arr.map((i)=>{return i.bname}));
          setbookArrSH((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }

        if (bookSubj == "Moti") {
          console.log("fetchmore reply aaya moti", response.data.Arr.length);
          setbookArrMoti((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "Sports") {
          console.log("fetchmore reply aaya Sports", response.data.Arr.length);
          setbookArrSports((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "Bio") {
          console.log("fetchmore reply aaya Bio", response.data.Arr.length);
          setbookArrBio((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "Yoga") {
          console.log("fetchmore reply aaya Yoga", response.data.Arr.length);
          setbookArrYoga((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "Health") {
          console.log("fetchmore reply aaya Health", response.data.Arr.length);
          setbookArrHealth((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "Cooking") {
          console.log("fetchmore reply aaya Cooking", response.data.Arr.length);
          setbookArrCooking((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "BS") {
          console.log("fetchmore reply aaya BS", response.data.Arr.length);
          setbookArrBS((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }


        if (bookSubj == "FC") {
          console.log("fetchmore reply aaya FC", response.data.Arr.length);
          setbookArrFC((prevArr) => {
            var newArr = prevArr.concat(response.data.Arr);
            var uniqueArr = newArr.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.bnumber === value.bnumber)
            );
            return uniqueArr;
          });
        }



      })
      .catch((error) => console.log(error));
  }


  


  const scrollRightSH = () => {
    divRefSH.current.scrollLeft += 150;
  };

  const scrollLeftSH = () => {
    divRefSH.current.scrollLeft -= 150;
  };

  const scrollRightMoti = () => {
    divRefMoti.current.scrollLeft += 150;
  };

  const scrollLeftMoti = () => {
    divRefMoti.current.scrollLeft -= 150;
  };
  const scrollRightSports = () => {
    divRefSports.current.scrollLeft += 150;
  };

  const scrollLeftSports = () => {
    divRefSports.current.scrollLeft -= 150;
  };

  const scrollRightBio = () => {
    divRefBio.current.scrollLeft += 150;
  };

  const scrollLeftBio = () => {
    divRefBio.current.scrollLeft -= 150;
  };
    
  const scrollRightYoga = () => {
    divRefYoga.current.scrollLeft += 150;
  };

  const scrollLeftYoga = () => {
    divRefYoga.current.scrollLeft -= 150;
  };
 
  const scrollRightHealth = () => {
    divRefHealth.current.scrollLeft += 150;
  };

  const scrollLeftHealth = () => {
    divRefHealth.current.scrollLeft -= 150;
  };

  const scrollRightCooking = () => {
    divRefCooking.current.scrollLeft += 150;
  };

  const scrollLeftCooking = () => {
    divRefCooking.current.scrollLeft -= 150;
  };

  const scrollRightBS = () => {
    divRefBS.current.scrollLeft += 150;
  };

  const scrollLeftBS = () => {
    divRefBS.current.scrollLeft -= 150;
  };


  const scrollRightFC = () => {
    divRefFC.current.scrollLeft += 150;
  };

  const scrollLeftFC = () => {
    divRefFC.current.scrollLeft -= 150;
  };
    
    
      
              const showPW = ()=>{
                const checkbox = checkboxRef.current
                const x = pwRef.current
                if(checkbox.checked){
                  x.type = "text"
                }
               else{
                 
                   x.type = "password"
               } 
            }


            const showPW1 = ()=>{
              const checkbox = checkbox1Ref.current
              const x = pw1Ref.current
              const y = pw2Ref.current
              if(checkbox.checked){
                x.type = "text"
                 y.type = "text"
              }
             else{
               
                 x.type = "password"
                   y.type = "password"
             } 
          }


            const handleChange = (event) => {
              const name = event.target.name;
              const value = event.target.value;
              setInputs(values => ({...values, [name]: value}))
            }

            const handleChange1 = (event) => {
              const name = event.target.name;
              const value = event.target.value;
              setInputs1(values => ({...values, [name]: value}))
            }
          

            const login = async (e) => {
              e.preventDefault();
    
              axios({
                method: 'post',
                url: `${yHost}/usersG/login`,
                data: inputs
                
              })
            .then((response) => {
          
              const data = response.data;
              console.log(data)
              alert(data.message)
              if(data.message=="you are logged in"){   setloggedIn(true) ; setloginModel(false) ; setloggedIn(true) ; setloginModel(false) }
              else{}
            })
            .catch((error) => console.log(error));
            }

            const logOut=async()=>{


             
              const result = await fetch('/booksG/logout', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({}),
              })
      
              .then((res) => res.json())
             // console.log(result.message)
              setloggedIn(false)
              alert(result.message)
             // window.location.replace("/")
            }
      
    


            const items = [
              {
                key: '1',
                style: {backgroundColor:"grey"},
                label: (
                  <span>{username}</span>
                ),
              },
             
              {
                key: '2',
                style: {backgroundColor:"brown"},
                onClick: () => navigate(`/profile`),
                label: (
                    <span>profile</span>
                ),
               
              },
              {
                key: '3',
                onClick:(e)=>logOut(),
                label: 'log out',
              },
              {
                key: '4',
                style: {backgroundColor:"aqua"},
                onClick:(e)=>navigate(`/transactions`),
                label: 'Transactions',
              },
            ];  




  return (
    <div style={{ paddingTop: "50px" }}>
     
      <div
        style={
          loading
            ? {
                width: "100%",
                height: "2000px",
                backgroundColor: "grey",
                zIndex: "10000000000",
                position: "fixed",
                top: 0,
                left: 0,
              }
            : { display: "none" }
        }
      >
        <Audio
          style={{
            marginRight: "auto",
            marginLeft: "auto",
            display: "block",
            marginTop: "300px",
          }}
          stroke="#98ff98"
          strokeOpacity={0.125}
          speed={0.75}
        />
      </div>

      {loginModel ? (
        <div
          style={{
            textAlign: "center",
            backgroundColor: "pink",
            width: "80%",
            height: "80%",
            zIndex: "100000",
            right: "0",
            left: "0",
            top: "0",
            bottom: "0",
            margin: "auto",
            position: "absolute",
          }}
        >
          <Button
            style={{ float: "right", marginTop: "10px", marginRight: "10px" }}
            type="primary"
            onClick={() => setloginModel(false)}
          >
            X
          </Button>

      
           {!signUpModel ?
          <form onSubmit={login} style={{borderRadius:"5px", borderStyle:"solid", borderWidth:"1px", borderColor:"grey",marginTop: "30px",marginBottom: "10px", width:"100%", maxWidth:"400px", marginLeft:"auto", marginRight:"auto", display:"block"}}   >
          <h1
             style={{ color: "orange", textAlign: "center" , padding:"5px", backgroundColor:"black", marginBottom:"10px" }}
          >
            Log In
          </h1>
            <input type="hidden" name="city" id="city"></input>
        
          

            <input
              autoFocus
              style={{ width: "300", padding: "10px" }}
              type="text"
              name="username"
              value={inputs.username || ""}
              onChange={handleChange}
              minLength="1"
              placeholder="Username or email or Mobile Number"
            ></input>
            <br />
            <br />

            <input
              style={{ width: "300", padding: "10px" }}
              type="password"
              id="password"
              autoComplete="new-password"
              name="password"
              value={inputs.password || ""}
              onChange={handleChange}
              minLength="3"
              placeholder="password"
              ref={pwRef}
            ></input>
            <br />
            <br />
            <div>
              
              <input
                ref={checkboxRef}
                style={{ width: "20px", height: "20px" }}
                type="checkbox"
                id="checkbox"
                onChange={() => showPW()}
              ></input>
              <label style={{ verticalAlign: "top" }} htmlFor="vehicle1">
                Show Password
              </label>
            </div>

            <button
              type="submit"
              className="submitLoginForm"
            >
              Login
            </button>
          </form>
            :
       
          <form ref={formElemRef} onSubmit={handleSubmit} style={{borderRadius:"5px", borderStyle:"solid", borderWidth:"1px", borderColor:"grey",marginTop: "30px",marginBottom: "10px", width:"100%", maxWidth:"400px", marginLeft:"auto", marginRight:"auto", display:"block"}} >
          <h1
            style={{ color: "orange", textAlign: "center" , padding:"5px", backgroundColor:"black" }}
          >
            Make a account
          </h1>
          <p style={{color:"#3498db", marginBottom:"20px", width:"95%"}}>Choose a unique username, eg. Mr.John can choose john_2024 or john007 or Mr_John123</p>
            <input
              autoFocus
              style={{ width: "300", padding: "10px" }}
              type="text"
              name="username"
              value={inputs1.username || ""}
              onChange={handleChange1}
              minLength="1"
              placeholder="choose a unique username"
            ></input>
            <br />
            <br />
            <input
              autoFocus
              style={{ width: "300", padding: "10px" }}
              type="email"
              name="email"
              value={inputs1.email || ""}
              onChange={handleChange1}
              minLength="1"
              maxLength={"50"}
              placeholder="Email"
            ></input>
            <br />
            <br />
            <p  style={{color:"#3498db", marginBottom:"20px", width:"95%"}}>Note: country code without leading &apos;+&apos;, and followed by phone number, for eg. if my country code is +91 and my phone number is 9828123456, I will enter it like this 919828123456</p>
            <input
              autoFocus
              style={{ width: "300", padding: "10px" }}
              type="number"
              name="phone"
              value={inputs1.phone || ""}
              onChange={handleChange1}
              minLength="1"
              maxLength={"50"}
              placeholder="Phone"
            ></input>
            <br />
            <br />

            <input
              style={{ width: "300", padding: "10px" }}
              type="password"
              id="newPassword"
            
              name="newPassword"
              value={inputs1.newPassword || ""}
              onChange={handleChange1}
              minLength="3"
              placeholder="password"
              ref={pw1Ref}
            ></input>
 <br />
 <br />
              <input
              style={{ width: "300", padding: "10px" }}
              type="password"
              id="cpassword"
             
              name="cpassword"
              value={inputs1.cpassword || ""}
              onChange={handleChange1}
              minLength="3"
              placeholder="confirm password"
              ref={pw2Ref}
            ></input>
            <br />
            <br />
            
              
              <input
                ref={checkbox1Ref}
                style={{ width: "20px", height: "20px" }}
                type="checkbox"
                id="checkbox"
                onChange={() => showPW1()}
              ></input>
              <label style={{ verticalAlign: "top" }} htmlFor="vehicle1">
                Show Password
              </label>
              <br />
              <br />
              <h2 style={{ color: "white" , marginBottom:"20px", color:"#3498db"}} >upload profile pic</h2>
              <div style={{ marginLeft:"auto", marginRight:"auto", display:"block", textAlign:"center"}}>
              <input  name="pic" type="file"  onChange={handleFileChange} accept=".jpg,.png" />
              </div>
         
          <img style={{maxWidth:"100px", marginTop:"10px", marginBottom:"10px", marginLeft:"auto", marginRight:"auto", display:"block", maxWidth:"225px"}} src={file} />

            <button
              type="submit"
              className="submitLoginForm"
            >
              Make Account
            </button>
          </form>
          } 
          
          {!signUpModel ?
          <div>
          <Button type="primary" onClick={()=>{setsignUpModel(true)}}>Make a account</Button> 
          <p>
            You have five login attempts only, if you try with wrong user ID or
            password, your account will be blocked for few hours
          </p>
          <p>
            If by mistake, you blocked your account, wait for few hours, it will
            be opened automatically
          </p>
          <p>
            If forgot you password, or other login problems contact{" "}
            <span style={{ color: "#3498db", fontWeight: "600" }}>
              Ms Pooja 7875450033
            </span>
            .
          </p>
          <p>
            For suggestions of improvement, new book request, please whatsapp to
            admin at +91 8591268994
          </p>
          </div>
          :
          <Button type="primary" onClick={()=>{setsignUpModel(false)}}>Log In Now</Button> 
          }  

        </div>
      ) : null}

      {loggedIn ? (
        <div
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "20px",
            right: "20px",
            padding: "10px",
          }}
          onClick={() => {
            console.log("go to route");
          }}
        >
          <Dropdown
            menu={{
              items,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <IconContext.Provider
                  value={{
                    size: "30px",
                    color: "orange",
                    className: "global-class-name",
                  }}
                >
                  <div>
                    <FaUserCircle className="hoverSearch arrow" />
                  </div>
                </IconContext.Provider>
              </Space>
            </a>
          </Dropdown>
        </div>
      ) : (
        <Button
          type="primary"
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",

            zIndex: "1000",
          }}
          onClick={() => setloginModel(!loginModel)}
        >
          Login
        </Button>
      )}

      <Link to="/search">
        <div
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "20px",
            right: "120px",
            padding: "10px",
          }}
          onClick={() => {
            console.log("go to route");
          }}
        >
          <IconContext.Provider
            value={{
              size: "30px",
              color: "orange",
              className: "global-class-name",
            }}
          >
            <div>
              <FaSearch className="hoverSearch arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </Link>

      <Link  to="/subscribe">
        <div
        
          className="subscribe"
          onClick={() => {
            console.log("go to route");
          }}
        >
         <p className="subscribeText">Subscribe</p>
        </div>
      </Link>


















      <div style={{ position: "relative" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftSH}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Self Help
        </p>
        <div style={contStyle} className={"onClass"} ref={divRefSH}>
          {bookArrSH.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    
                    src={
                    //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "SH" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                    
                   
                    onMouseEnter={() => setHover("SH" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>

                <p
                  
                  style={{
                    color: "white",
                    marginTop: "60px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
                 
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}
          <div style={{ backgroundColor: "white" }} ref={bottomSH}>
            loading
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightSH}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>

      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftMoti}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Motivational
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefMoti}>
          {bookArrMoti.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                 <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Moti" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Moti" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                 
                  style={{
                    color: "white",
                    marginTop: "60px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
          
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomMoti}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightMoti}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>


      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftBS}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Best-Seller
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefBS}>
          {bookArrBS.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "BS" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("BS" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                  
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
              
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomBS}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightBS}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>



      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftSports}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Sports
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefSports}>
          {bookArrSports.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                     // `${yHost}/public/image/` +
                     `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Sports" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Sports" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
              
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomSports}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightSports}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>


      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftBio}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Biography / Autobiography
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefBio}>
          {bookArrBio.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                 <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Bio" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Bio" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
          
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomBio}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightBio}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>


      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftYoga}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Yoga
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefYoga}>
          {bookArrYoga.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Yoga" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Yoga" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                  
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
                  
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomYoga}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightYoga}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>

      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftHealth}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Health
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefHealth}>
          {bookArrHealth.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Health" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Health" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                  
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
                
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomHealth}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightHealth}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>


      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftCooking}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Cooking
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefCooking}>
          {bookArrCooking.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "Cooking" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("Cooking" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
          
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
                  
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomCooking}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightCooking}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>


      <div style={{ position: "relative", marginTop: "50px" }}>
        <div
          className="hoverButton"
          style={{
            zIndex: "1000",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            left: "5%",
          }}
          onClick={scrollLeftFC}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronLeft className="arrow" />
            </div>
          </IconContext.Provider>
        </div>

        <p
          style={{ color: "white", marginLeft: "30px", marginBottom: "-50px" }}
        >
          Fiction
        </p>

        <div style={contStyle} className={"onClass"} ref={divRefFC}>
          {bookArrFC.map((book, index) => {
            return (
              <div key={index} className="divStyle">
                  <Link to="/bookDetails" state={{bnumber: book.bnumber}}>
                  <img
                    src={
                       //  `${yHost}/public/image/` +
                    `${yHost}/booksG/image/` +
                      book.bnumber +
                      ".jpg"
                    }
                    className="bookImg"
                    style={
                      hover != "FC" + index
                        ? {
                           
                            marginRight: "30px",
                            marginTop: "30px",
                          }
                        : {
                           
                            transform: "scale(1.5)",
                            overflow: "visible",
                            
                          }
                    }
                   
                    onMouseEnter={() => setHover("FC" + index)}
                    onMouseLeave={() => setHover("")}
                  />
                </Link>
                <p
                  
                  style={{
                    color: "white",
                    marginTop: "80px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                  }}
                >
                  {book.bname}
                </p>

                <p
              
                  style={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    textWrap: "wrap",
                    overflow: "hidden",
                  }}
                >
                  <span style={{ color: "white" }}>Author:</span>{" "}
                  <span style={{ color: "orange" }}>{book.author}</span>
                </p>
              </div>
            );
          })}

          <div style={{ backgroundColor: "white" }} ref={bottomFC}>
            loading...
          </div>
        </div>
        <div
          className="hoverButton"
          style={{
            position: "absolute",
            right: "0",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
          onClick={scrollRightFC}
        >
          <IconContext.Provider
            value={{
              size: "100px",
              color: "white",
              className: "global-class-name",
            }}
          >
            <div>
              <GoChevronRight className="arrow" />
            </div>
          </IconContext.Provider>
        </div>
      </div>



     <Button type={"primary"} style={{padding:"10px", float:"right", marginRight:"20px", marginBottom:"50px", marginTop:"40px"}}   onClick={() => navigate(`/Search`)}>Explore More Categories</Button>


    </div>
  );
}

export default App;



