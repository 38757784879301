import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from "./error-page";
import BookDetails from "./bookDetails"; //component name start with must be capital letter
import SearchResult from "./searchResult" //component name start with must be capital letter
import Search from "./search"
import Subscribe from "./subscribe"
import Profile from "./profile"
import Transactions from "./transactions"




import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
 
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/bookDetails",
    element: <BookDetails />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/searchResult",
    element: <SearchResult/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/search",
    element: <Search/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/subscribe",
    element: <Subscribe/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/profile",
    element: <Profile/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/transactions",
    element: <Transactions/>,
    errorElement: <ErrorPage />,
  },
]);



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 
    <RouterProvider router={router} />
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
