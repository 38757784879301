


import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Audio } from 'react-loading-icons' 
import { Outlet, Link } from "react-router-dom";
import { Button } from "antd";

import { useNavigate } from "react-router-dom";


function Search() {
  const [bname, setbname] = useState("");
  const [author, setauthor] = useState("");
  const [subject, setsubject] = useState("");
  const [language, setlanguage] = useState("");
  const [loading, setLoading] = useState(true)

  const [subjBnumberArr, setSubjBnumberArr] = useState([]);
  const [languageList, setlanguageList] = useState([]);

 
  const navigate = useNavigate();
  //var yHost = "http://localhost:8087"
  var yHost = ""

  useEffect(() => {
    axios
      .get(`${yHost}/booksG/allsubjectsG`)  
      .then((response) => {
        const data = response.data;
        setSubjBnumberArr(response.data.subjBnumberArr);
        setlanguageList(response.data.languageList);
        console.log(response.data.languageList)
        setLoading(false)
      })
      .catch(() => alert("error"));
  }, []);



  const searchBooks = async (e) => {
    e.preventDefault();

    
  };


  return (
     <div>
     {loading?
      <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
      <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
      </div>
           :
    <div style={{ backgroundColor: "black", width: "100%", height: "100%" }}>
      <h1 style={{ color: "white", textAlign: "center", margin: "20px" }}>
        Search Your Books by Book Name or Author Name
      </h1>

      <form onSubmit={searchBooks} style={{ textAlign: "center" }}>
        <input
          autoFocus
          style={{ width: "600px", padding: "10px" }}
          type="text"
          name="bname"
          value={bname || ""}
          onChange={(e) => setbname(e.target.value)}
          minLength="3"
          placeholder="Book Name"
        ></input>
        <br />
        <br />

        <input
          style={{ width: "600px", padding: "10px" }}
          type="author"
          autoComplete="new-password"
          name="text"
          value={author || ""}
          onChange={(e) => setauthor(e.target.value)}
          minLength="3"
          placeholder="Author"
        ></input>
        <br />
        <br />

<Link to="/searchResult" state={{bname:bname, author:author}} style={{textDecoration: 'none'}}>
<h1 style={{
            marginTop: "10px",
            marginBottom: "30px",
            padding: "10px",
            backgroundColor:"orange",
            color:"white",
            width:"600px",
            textAlign:"center", 
            marginLeft:"auto"  ,
            marginRight:"auto",
            display:"block"
          }}>Search</h1>
</Link>
      </form>






      <h1 style={{ color: "white", textAlign: "center", margin: "20px" }}>
        Other way is to choose your favorite Subject / Genre, below
      </h1>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap",justifyContent: "center" }}>
        {subjBnumberArr.map((item, index) => {
          return (
            <Link key={index} to="/searchResult" state={{subject:item.subject}} style={{textDecoration: 'none'}}>
            <div
              key={index}
              style={{
               padding:"10px",
               
                marginTop: "25px",
               marginLeft:"35px",
               marginRight:"35px",
               
              }}
            >
              <img
              
                src={
                  `${yHost}/booksG/image/` + item.bnumber + ".jpg"
                }
                style={ {
                    maxWidth: "200px",
                    width: "150px",
                    height: "auto", 
                    marginRight:"auto",
                    marginLeft:"auto",
                    display:"block"
                  } }
                width={200}
                height={300}
                alt="Picture"
              />
              <p style={{textAlign:"center", color:"white", textWrap:"wrap", color:"orange"}}>{item.subject}</p>
              <p style={{textAlign:"center", color:"white",textWrap:"wrap"}}>Search this Subject / Genre</p>
            </div>
            </Link>
          );
        })}
      </div>
    </div>
      }
    </div>
  );
}

export default Search;

  
  