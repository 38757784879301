

import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";


import { Bars, Puff, SpinningCircles, Audio } from "react-loading-icons";
import { Button } from "antd";




export default function Subscribe() {

    const [username, setusername] = useState("")
    const [phone, setphone] = useState(NaN)
    const [ loading, setloading] = useState (true)

   // var yHost = "http://localhost:8087"
    var yHost = ""


  useEffect(() => {
    phoneUsername()
  }, []);

  const phoneUsername = () => {
    axios({
      method: "get",
      url: `${yHost}/usersG/phoneUsername`,
      withCredentials: true,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        if(data.username) { 

            setusername(data.username)
            setphone(data.phone)
        }
      
       
        setloading(false);

      })
      .catch((error) => console.log(error));
  };

  const payamount = async (amount,pid) => {
    console.log("buySubscription", amount,pid)

    axios({
      method: 'post',
      url: `${yHost}/usersG/createOrderForSubscription`, 
      data: { username: username, amount:amount,  pid:pid },
    })
      .then((response) => {
        const res = response.data;
        //console.log(res.msg)

        if (res.success) {
          console.log(res)
          var options = {
            "key": "" + res.key_id + "",
            "amount": "" + res.amount + "",
            "currency": "INR",
            "name": "" + res.product_name + "",
            "description": "" + res.description + "",
            "image": `https://cdn.pdfdrive.com/assets/thumbs/9ce/9cec9c5bb16628052fa30cd80e08b693.jpg`, //yaha same book ka image do
            "order_id": "" + res.order_id + "",
            "handler": (response) => {

              alert("Payment Successfull, you can read");
              //yaha koi bhi state console nhi ho rahi ? why
              // window.open("/","_self")
              // alert(response.razorpay_payment_id);
              // alert(response.razorpay_order_id);
              // alert(response.razorpay_signature);


              axios({
                method: 'post',
                url: `${yHost}/verifybookRent`,
                data: {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  

                },
              })
                .then((result1) => {
                  console.log('yaaya', result1.data)


                  if (result1.data.msg == "") { alert("some error in system, please contact support if payment has been debited from your account, and it is still not showing at this page, after you refresh this page") }
                  if (result1.data.msg == "payment confirmed") {
                    alert("payment confirmed")
                    console.log(result1.data.rentedBooksArr)

                    this.setState({ rentedBooksArr: result1.data.rentedBooksArr })

                    this.setState({ keycount: this.state.keycount + 1 })

                  }
                  if (result1.data.msg == "Payment verification failed") { alert("Payment verification failed, please contact customer care") }
                  //window.open("/home", "_self")
                })
                .catch((err1) => console.log(err1));
            },
            "prefill": {
              "contact": "" + res.contact + "",
              "name": "" + res.name + "",
              "email": "" + res.email + ""
            },
            "notes": {
              "description": "" + res.description + ""
            },
            "theme": {
              "color": "#2300a3"
            }
          };

          var razorpayObject = new window.Razorpay(options);
          razorpayObject.on('payment.failed', function (response) {
            alert("Payment failed, please try again")
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
          });
          razorpayObject.open();
        }
        else {
          alert(res.msg);
        }



      })
      .catch((error) => console.log(error));
  }





  return (
     <div>


         

         {loading?
        <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
        <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
        </div>
             :
    <div>  
         <h1 style={{color:"white", textAlign: "center", marginTop:"20px"}}>Subscription Plans</h1>
         <div style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-around", padding:"10px"}}>


             <div style={{ width:"45%", maxWidth:"600px", minWidth:"400px",borderRadius:"5px", borderStyle:"solid", borderWidth:"1px",marginTop:"20px"}}>
                <h1 style={{color:"white", textAlign: "center", padding:"10px", backgroundColor:"orange"}}>Silver Plan</h1>
                <p className="paraText">You can Open maximum 10 different books in a day, You can open these 10 books multiple times on the same day.</p>
                <p className="paraText">You can Open maximum 100 different books a month, You can open these 100 books multiple times in same month.</p>

                <p className="paraText">You can not read premium books</p>
                <p className="paraText">You can not use fast links</p>
                
                <div  style={{display:"table", marginTop:"30px", marginLeft:"30px"}}>
                <div style={{display:"table-row", height:"50px", }}>
                <p className="paraTextOrange">60 INR for one month</p>
                <Button style={{marginLeft:"20px", display:"table-cell"}} onClick={()=>{payamount(60,'silver1m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">160 INR for three month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(160,'silver3m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">300 INR for six month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(300,'silver6m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">500 INR for a year</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(500,'silver12m')}}>Buy</Button>
                </div>

                </div>


               
                
             </div>


             <div style={{ width:"45%", maxWidth:"600px", minWidth:"400px",borderRadius:"5px", borderStyle:"solid", borderWidth:"1px", marginTop:"20px"}}>
             <h1 style={{color:"white", textAlign: "center",padding:"10px", backgroundColor:"orange" }}>Gold Plan</h1>
             <p className="paraText">You can Open maximum 20 different books in a day, You can open these 20 books multiple times on the same day.</p>
             <p className="paraText">You can Open maximum 200 different books a month, You can open these 200 books multiple times in same month.</p>
             <p className="paraText">You can not use fast links</p>
             <p className="paraText">You can not read premium books</p>
          

             <div  style={{display:"table", marginTop:"30px", marginLeft:"30px"}}>
                <div style={{display:"table-row", height:"50px", }}>
                <p className="paraTextOrange">100 INR for one month</p>
                <Button style={{marginLeft:"20px", display:"table-cell"}} onClick={()=>{payamount(100,'gold1m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}} onClick={()=>{payamount(270,'gold3m')}}>
                <p className="paraTextOrange">270 INR for three month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">500 INR for six month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(500,'gold6m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">900 INR for a year</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(900,'gold12m')}}>Buy</Button>
                </div>

                </div>



             
            
             
             </div>

             <div style={{ width:"45%", maxWidth:"600px", minWidth:"400px",borderRadius:"5px", borderStyle:"solid", borderWidth:"1px",marginTop:"20px"}}>
             <h1 style={{color:"white", textAlign: "center",padding:"10px", backgroundColor:"orange" }}>Diamond Plan</h1>
             <p className="paraText">You can Open maximum 40 different books in a day, You can open these 40 books multiple times on the same day.</p>
             <p className="paraText">You can Open maximum 400 different books a month, You can open these 400 books multiple times in same month.</p>
             <div className="demonstratorText">
             <p className="paraText">You can use fast links, it is useful for lengthy books.</p>
             <p className="paraText">Here see the difference btw ordinary link and fast link, for a lengthy book.</p>
             <div style={{display:"flex", justifyContent:"space-evenly"}}>
             <Button style={{marginTop:"10px"}} type="primary">Ordinary Link</Button>
             <Button style={{marginTop:"10px"}} type="primary">Fast Link</Button>
             </div>
             </div>
             <p className="paraText">You can read premium books</p>
             
             <div className="demonstratorText">
             <p className="paraText">For College Teachers / Demonstrators, they can use our eBooks during their lectures.<br/>They can open any textbook via fast link and then can draw over textbook pages.<br/> 
             At fast-Link Page, at bottom right corner, it is a button, where they can use draw over page feature. <span style={{color:"orange"}}>Click above fast link, and in next screen find button at bottom right corner.</span><br/>
                They can draw over page by a stylus, if they have touch screen, if they don&apos;t have, they can draw by mouse only, After lecture, by save button they can save edited page at our server.<br/>
                It will also provide you sharable link, which can be shared to anyone, same link google drive.<br/>
                You can access your saved pages in my pages menu.<br/>
                It will be keep save for one month at our server<br/>
                You can save maximum 1000 pages in a month<br/>
                </p>

                <Button style={{marginTop:"10px"}} type="primary">See Details</Button>
                
             
            </div>

             <div  style={{display:"table", marginTop:"30px", marginLeft:"30px"}}>
                <div style={{display:"table-row", height:"50px", }}>
                <p className="paraTextOrange">200 INR for one month</p>
                <Button style={{marginLeft:"20px", display:"table-cell"}} onClick={()=>{payamount(200,'diamond1m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">550 INR for three month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(550,'diamond3m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">1000 INR for six month</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(1000,'diamond6m')}}>Buy</Button>
                </div>

                <div style={{display:"table-row", height:"50px"}}>
                <p className="paraTextOrange">1800 INR for a year</p>
                <Button style={{marginLeft:"20px",display:"table-cell"}} onClick={()=>{payamount(1800,'diamond12m')}}>Buy</Button>
                </div>

                </div>



             
            
             
             </div>


         </div>

    </div> }
     </div>
  );
}



       
     
        