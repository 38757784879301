





import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from 'react';
import moment from "moment"
import axios from "axios";
import { useLocation } from 'react-router-dom';

import { Outlet, Link } from "react-router-dom";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 
import { Button } from 'antd';


export default function SearchResults() {

  const [items, setitems] = useState([])
  const [wholeArr, setwholeArr] = useState([])
  const [fixedwholeArr, setfixedwholeArr] = useState([])
  const [keyVal, setkeyVal] = useState(0)
  const [loading, setloading] = useState(true)
  const [hasMore, sethasMore] = useState(true)
  const [yObj, setyObj] = useState({})
  const [ fetchMoreChala, setfetchMoreChala] = useState(0)
  let { state } = useLocation();


  
  //var yHost = "http://localhost:8087"
  var yHost = ""


  useEffect(()=>{
   
  var yObj = new Object()
  if(state.subject){yObj.sub = state.subject}
  if(state.author){yObj.author = state.author}
  if(state.bname){yObj.bname = state.bname}
  
  yObj.keyVal = keyVal

  console.log("yObj",yObj)
  setyObj(yObj)

  bookfetching(yObj)
  

  },[])

  
  useEffect(()=>{
  console.log("items", items.map((i)=>{ return i.bnumber}))
  console.log("wholeArr", wholeArr.map((i)=>{ return i.bnumber}))
  },[items, wholeArr])



    useEffect(()=>{
        console.log("fetchmorechala count", fetchMoreChala)
    },[fetchMoreChala])
  

    const bookfetching = (YObj) => {
        console.log("first fetch chala")
        //man lo direct search button dabaya
        //fixedWholeArr bana 14600 length ka
        //Infinite scroll items state mein se values leta hai
        //fetchmoredata se 10 value wholeArr mein se kat kar items mein jodte jaa rahe hain
        axios({
          method: 'post',
          url: `${yHost}/booksG/fetching`,
          data: YObj
        })
          .then((response) => {
         
        
               setloading(false)
               const data = response.data
               if (!data.length) { alert('no records') }
    
               else {
       
                
                   setfixedwholeArr(data) //fetching se total books aayi, use fixedwholeArr mein store kiya
                   console.log("books received", data.length  )
                  
    
            
       
          
          
               if (data.length <= 10) {
                 console.log('10 yaa kam')
       
                    setitems(data )
               }
      
               else if (data.length > 10) {  
                                
      
      
                   const shuruArr = data.splice(0, 10);  //shuru ke 10 item liye
    
                console.log("shuruArrlength ab items", shuruArr.length)
                console.log("data.length splice ke bad, ab wholeArr", data.length)
               
      
              
                 //peeche se data aaya use fixedwholeArr mein store kiya, data meins e aage ki 10 book kat kar items ko di, baki wholeArr mein store ki
                 setitems(shuruArr)
                 setwholeArr(data)
               }
            }
        }).catch((error) => console.log(error));
    
           
    
      };



  const fetchMoreData = () => {
   console.log("fetchmore chala", fetchMoreChala)




var fetchMoreChalaLocal = fetchMoreChala+1
console.log("fetchMoreChalaLocal",fetchMoreChalaLocal)
setfetchMoreChala(fetchMoreChalaLocal)
if( fetchMoreChalaLocal % 8 == 0 ){  

 



  var yObjLocal = yObj
  yObjLocal.keyVal = yObjLocal.keyVal+1

axios({
    method: 'post',
    url: `${yHost}/booksG/fetching`,
    data: yObjLocal
  })
    .then((response) => {
      const data = response.data; 

      console.log("API more se books milie", data.length)
      console.log("wholeArr length", wholeArr.length)
      setwholeArr(wholeArr.concat(data))
      console.log("more se books mili vo wholeArr mein concat ki wholeArr length hui", wholeArr.length)
      setyObj(yObjLocal)
      
    })
    .catch((error) => console.log(error));

}

    if (!wholeArr.length) {
        sethasMore(false)
        //return se fetchMoreData function yahi stop ho jayega
        return;
      }

      if (wholeArr.length >= 10) { 
        
           // console.log("wholeArr", wholeArr.length)
            var yahaKawholeArr  = wholeArr
            var splicedPart = yahaKawholeArr.splice(0, 10)
            setitems((prev)=>prev.concat(splicedPart))
            //console.log("wholeArr after 10 minus", yahaKawholeArr.length)
            setwholeArr(yahaKawholeArr)
        
      }
  
      else if (wholeArr.length < 10) {
        console.log('wholeArr 10 se kam')
       
            setitems((prev)=>prev.concat(wholeArr))
            setwholeArr([])
           
     
      }
  


  };


  return (
    <div>
        {loading?
        <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
        <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
        </div>
             :
    <div>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}

            loader={<h3 style={{ color: "orange", textAlign: "center" }}>Loading...</h3>}
            //key={this.state.keycount}
            endMessage={
              <h3 style={{ textAlign: "center", color: "orange",float: "right" }}>
                You have seen it all, More Textbooks are coming soon.
              </h3>
            }
          >
            
            {items.map((i,index)=>{ return (
                <div style={{width:"100%",maxWidth:"600px", borderColor: "aqua", borderWidth:"1px", borderStyle:"solid", borderRadius:"5px", marginRight:"auto", marginLeft:"auto", display:"block",paddingLeft:"20px",paddingRight:"10px",marginTop:"20px" }} key={index}>
                
                <h2 style={{color:"white", textAlign:"center",marginTop:"20px"}}>{i.bname}</h2>
                <Link href=  {{pathname: "/bookDetails" ,  query:{bnumber:i.bnumber}     }}  style={{textDecoration: 'none'}}> 
                <img
                 
                  src={
                    `${yHost}/public/image/` +
                    i.bnumber +
                    ".jpg"
                  }
                  style={
                      {
                        marginRight:"auto", marginLeft:"auto", display:"block", width: "90%", height:"auto",maxWidth:"300px", marginTop:"20px",marginBottom:"20px"
                      }
                  }
                 
                  alt="Picture"
                />
                </Link>
                 <p style={{color:"orange", textAlign:"center"}}>{i.subject1}</p>
              <p style={{color:"orange",textAlign:"center"}}>{i.subject2}</p>
              <p style={{color:"orange",textAlign:"center", marginBottom:"20px"}}>{i.subject3}</p>
              <p><span style={{color:"orange"}}>Author: </span><span style={{color:"white"}}>{i.author}</span></p>
              <p><span style={{color:"orange"}}>Edition: </span><span style={{color:"white"}}>{i.edition}</span></p>
                </div> 
                
            )})}

          </InfiniteScroll>
      
      </div>
        }
    </div>
  );
}
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8
};

