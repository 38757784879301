
import moment from "moment";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Bars, Puff, SpinningCircles, Audio } from 'react-loading-icons' 


export default function Transactions() {

    const [transaction, settransaction] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(()=>{

      
        fetchTransactions()
    
        console.log("useEffect")
        
    },[])

    //var yHost = "http://localhost:8087"
    var yHost = ""

    const fetchTransactions = () => {
      
         axios({
           method: 'post',
           url: `${yHost}/usersG/fetchTransactions`,
           withCredentials: true, //ye cookies carry karega
         })
       .then((response) => {
         const data = response.data
         console.log(data)
         setloading(false)
         settransaction(data.yourTransactions)
         

       })
       .catch((error) => {console.log(error); setloading(false); alert("some error")});
         
     }




  return (
    <div>
    {loading?
        <div style={{width:"100%", height:"3000px", backgroundColor:"brown", position:'absolute'}}>
        <Audio style={{marginRight:"auto", marginLeft:"auto", display:"block", marginTop:"300px"}}  stroke="#98ff98" strokeOpacity={.125} speed={.75} />
        </div>
             :
    <section style={{marginTop:"20px", marginBottom:"20px"}}>
        <h1 style={{color:"white", textAlign:"center"}}>Your Transactions for Monthly Subscription</h1>
    <div class="row flex">
      <div style={{ padding:"5px", display: "flex", justifyContent: "center"}}>
        <div>
          <table style={{color:"seashell" , minWidth: "350px"}}>  
            <tr>
              <td style={{color:"orange", fontWeight:600}}>Date</td>
              <td style={{color:"orange", fontWeight:600}}>Amount</td>

 
                <td style={{color:"orange", fontWeight:600}}>Order Id</td>
           


                  <td style={{color:"orange", fontWeight:600}}>Payment ID</td>
                  <td style={{color:"orange", fontWeight:600}}>Validity From</td>
                  <td style={{color:"orange", fontWeight:600}}>To</td>
                  <td style={{color:"orange", fontWeight:600}}>coupon id</td>
            </tr>

             {transaction.map((row)=>{
                return (
                    <tr>
                    <td>
                       {moment(row.rechargeOn).format('MMMM Do YYYY, h:mm a')} 
                    </td>
                    <td>
                       {row.amount/100} 
                    </td>
    
                   
                      <td>
                         {row.orderid} 
                      </td>
                      
    
    
                        <td>
                           {row.paymentId} 
                        </td>
                        <td>
                           {moment(row.oldValidity).format('MMMM Do YYYY, h:mm a')} 
                        </td>
                        <td>
                           {moment(row.newValidity).format('MMMM Do YYYY, h:mm a') }
                        </td>
                        <td>
                           {row.pid} 
                        </td>
                  </tr>
                )
             })}  
         



          </table>

        </div>
      </div>
    </div>
    </section>}
    </div>
  );
}

