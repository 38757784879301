


import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "axios";







import { Bars, Puff, SpinningCircles, Audio } from "react-loading-icons";
import { Button } from "antd";

import { Outlet, Link } from "react-router-dom";





export default function Profile() {
  const [profile, setProfile] = useState({});
  const [loading, setloading] = useState(true);

  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [cpassword, setcpassword] = useState("");
  const [file, setFile] = useState();
  const [bookseenthismonth, setbookseenthismonth] = useState(0)
  const [bookseentoday, setbookseentoday] = useState(0)

   const formElemRef = useRef()

 

 

  function handleChange(e) {
    // console.log(e.target.files[0])
    // console.log(e.target.files[0].uri)
    // console.log(e.target.files[0].url)

     if(e.target.files[0].size > 2000000 ){alert("file should be less then 2 MB"); return}
      setFile(URL.createObjectURL(e.target.files[0]));
  }

  useEffect(() => {
    fetchProfile();
    
  }, []);

  //var yHost = "http://localhost:8087"
  var yHost = ""


  const fetchProfile = () => {
    axios({
      method: "get",
      url: `${yHost}/usersG/profile`,
      withCredentials: true,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        if(data.msg=="please login") {alert("please login")}
        else{
          setProfile(data.profile);
          setbookseentoday(data.bookseentoday)
          setbookseenthismonth(data.bookseenthismonth)
        }
       
        setloading(false);

      })
      .catch((error) => console.log(error));
  };






 const handleSubmit=(e)=>{
    e.preventDefault()
    if(email !="" && (!email.includes("@") || !email.includes(".")) ) {alert("please enter correct email id"); return}
   
    if(password && !cpassword){alert("enter password once again in confirm pasdword field"); return}
    if(password &&   password != cpassword){alert("you entered different password in confirm password field"); return}

      const formData = new FormData(formElemRef.current);

     // formData.append('pic', file);
    
    // if(email){data.email = email}
    // if(password){data.password = password }
    // if(phone){data.phone = phone }

     console.log(formData)
    axios({
      method:'post',
      url:`${yHost}/usersG/updateProfile`, 
      data: formData, 
      withCredentials: true,
 })
    .then((response) => {
        const data = response.data;
       if(data.msg == "success"){alert("updated");
        //location.reload();
    }
      })
      .catch((error) => console.log(error));

 }

  return (
    <div>
      {loading ? 
        <div
          style={{
            width: "100%",
            height: "3000px",
            backgroundColor: "brown",
            position: "absolute",
          }}
        >
          <Audio
            style={{
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
              marginTop: "300px",
            }}
            stroke="#98ff98"
            strokeOpacity={0.125}
            speed={0.75}
          />
        </div>
       : (
        <div
          style={{ marginRight: "auto", marginLeft: "auto", display: "block", width:"100%", maxWidth:"800px", paddingLeft:"10px" }}
        >
          <h1
            style={{ color: "white", textAlign: "center", marginTop: "30px" }}
          >
            {profile.username}
          </h1>
          <img
          
            src={
              `${yHost}/public/profilePicsG/pic_` +
              profile.username +
              ".jpg"
            }
            width="0"
            height="0"
            sizes="100vw"
            style={{
              width: "100%",
              maxWidth: "300px",
              height: "auto",
              marginRight: "auto",
              marginLeft: "auto",
              display: "block",
              marginTop: "40px",
            }}
            alt="Picture"
          />
    
          <form ref={formElemRef} onSubmit={handleSubmit}>
          <h2 style={{ color: "white" , marginBottom:"20px"}} >update profile pic</h2>
          <input  name="pic" type="file" onChange={handleChange} accept=".jpg" />
          <img style={{maxWidth:"100px"}} src={file} />


        
          <p style={{  marginTop: "30px" }}>
            <span style={{ color: "white" }}>First Name : </span>
            <span style={{ color: "orange" }}>{profile.fname}</span>
          </p>
          <p style={{ marginTop: "30px" }}>
            <span style={{ color: "white" }}>Surname : </span>
            <span style={{ color: "orange" }}>{profile.surname}</span>
          </p>

          {profile.diamondUser && profile.premiumUser ? (
            <p style={{ marginTop: "30px" }}>
              <span style={{ color: "white" }}>Account Type : </span>
              <span style={{ color: "orange" }}>Diamond</span>
            </p>
          ) : null}
          {!profile.diamondUser && profile.premiumUser ? (
            <p style={{ marginTop: "30px" }}>
              <span style={{ color: "white" }}>Account Type : </span>
              <span style={{ color: "orange" }}>Gold</span>
            </p>
          ) : null}
          {!profile.diamondUser && !profile.premiumUser ? (
            <p style={{  marginTop: "30px" }}>
              <span style={{ color: "white" }}>Account Type : </span>
              <span style={{ color: "orange" }}>Silver</span>
            </p>
          ) : null}

          <p style={{  marginTop: "30px" }}>
            <span style={{ color: "white" }}>Account Validity : </span>
            <span style={{ color: "orange" }}>
              {moment(profile.validity).format("MMMM Do YYYY, h:mm a")}
            </span>
          </p>

          <p style={{  marginTop: "30px" }}>
            <span style={{ color: "white" }}>Book Reads today : </span>
            <span style={{ color: "orange" }}>
              {bookseentoday}
            </span>
          </p>

          <p style={{  marginTop: "30px" }}>
            <span style={{ color: "white" }}>Book Reads in this Month : </span>
            <span style={{ color: "orange" }}>
              {bookseenthismonth}
            </span>
          </p>




          {new Date() > profile.validity ? (
            <p style={{ color: "white" }}>Subscribe</p>
          ) : null}

          {!profile.diamondUser ? (
            <p style={{ color: "white" }}>upgrade your account</p>
          ) : null}


          <input
            style={{ width: "600px", padding: "10px", marginBottom:"20px", marginTop:"20px" }}
            type="email"
            name="email"
            value={profile.email || ""}
            onChange={(e) => setemail(e.target.value)}
            minLength="4"
            placeholder="update your email"
          ></input>
         
          <input
            style={{ width: "600px", padding: "10px",  marginTop:"20px" }}
            type="number"
            name="phone"
            value={profile.phone || ""}
            onChange={(e) => setphone(e.target.value)}
            minLength="4"
            placeholder="update your phone"
          ></input>
        <p style={{color:"orange", marginBottom:"20px"}}>Note: country code without leading &apos;+&apos;, and followed by phone number, for eg. if my country code is +91 and my phone number is 9828123456, I will enter it like this 919828123456</p>

          <input
            style={{ width: "600px", padding: "10px" ,marginBottom:"20px"}}
            type="text"
            name="password"
            value={password || ""}
            onChange={(e) => setpassword(e.target.value)}
            minLength="3"
            placeholder="update your password"
          ></input>

          <input
            style={{ width: "600px", padding: "10px", marginBottom:"20px", marginBottom:"20px" }}
            type="text"
            name="cpassword"
            value={cpassword || ""}
            onChange={(e) => setcpassword(e.target.value)}
            minLength="3"
            placeholder="confirm new password"
          ></input>

          <button className="submitBtn" type="submit" >Submit</button>
           </form>
           {profile.username ? 
           <Link to="/transactions" style={{textDecoration:"none"}} ><h4 style={{color: "orange" , marginBottom:"20px"}} >Transactions for Monthly Subscription</h4></Link>
           :null}
          
        </div>
      )}
    </div>
  );
}
